.btn-custom-success {
    background-color: #50A313; /* Color de fondo */
    color: #fff; /* Color del texto */
    font-size: 20px; /* Tamaño del texto */
    font-weight: 300; /* Grosor del texto */
    padding: 12px 24px; /* Espaciado interno: arriba/abajo, izquierda/derecha */
    border: none; /* Sin bordes */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cursor en forma de mano al pasar por encima */
    transition: background-color 0.2s; /* Transición suave al cambiar de color */
  }
  
  .btn-custom-success:hover {
    background-color: #407a22; /* Color de fondo al pasar el mouse */
  }
  
  .btn-custom-success:active {
    background-color: #367a1e; /* Aún más oscuro al hacer clic */
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); /* Sombra interior para efecto de pulsación */
  }
  